import React from "react"
import GeneralComp from "../../components/generalComp"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { firstsem } from "../../data/academics"
import { getWords } from "../../helper-functions"
function FirstSem(props) {
  return (
    <div>
      <Layout>
        <SEO
          title={firstsem.title}
          description={getWords(firstsem.content, 60)}
        />
        <GeneralComp {...firstsem} />
      </Layout>
    </div>
  )
}

export default FirstSem
